::-webkit-scrollbar {
  width: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

html,
body,
#root {
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

html,
body,
input {
  border-radius: 0;
  box-shadow: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.AppContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 5vh;
}

.AppBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.AppTopNav {
  width: 100%;
  height: 5vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f2f2f2f2;
  padding: 8px;
  z-index: 2;
}

.TopNavList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-right: 32px;
}

.TopNavItem {
  padding: 0 16px;
  color: #3D4852;
  font-size: 14px;
}

.TopNavItem:not(:last-child) {
  border-right: 1px solid #3D4852;
}

.AppContentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
.AppHeader {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 15vh;
  width: 100%;
  max-width: 1024px;
}

.AppHeading {
  font-weight: 300;
  font-size: 14px;
  color: #3D4852;
  padding-left: 16px;
}

.AppHeadingWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 15vh;
  align-items: flex-end;
  justify-content: flex-end;
}

.Section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 85vh;
  background-color: #fff;
  padding: 14px 0;
}

.SectionContent {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1024px;
  border-radius: 4px;
}

.Contact {
  margin: 16px 0;
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.ContactText {
  font-weight: 300;
  padding: 0 16px;
  color: #3D4852;
  font-size: 16px;
}

.PlaceHolderImage {
  height: "800px"
}

.Imprint {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 1024px;
}

.ImpressumText {
  color: #3D4852;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 4px;
}

.ImpressumHeading {
  color: #3D4852;
  font-size: 16px;
  font-weight: 300;
  padding: 16px 0;
  text-decoration: underline;
}

@media screen and (max-width: 40em) {
  .AppHeader {
    flex-direction: column;
    flex: 0 0 25vh;
  }

  .AppHeadingWrapper {
    align-items: center;
    justify-content: center;
  }

  .AppHeading {
    width: 100%;
  }
}
